import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

import { createPinia } from "pinia";

const pinia = createPinia();

/* Ionic Components */
import {
  IonBackdrop,
  IonButtons,
  IonCheckbox,
  IonMenuButton,
  IonSpinner,
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonList,
  IonNote,
  IonHeader,
  IonToolbar,
  IonButton,
  IonFooter,
  IonGrid,
  IonCol,
  IonRow,
  IonTitle,
  IonMenu,
  IonListHeader,
  IonItemGroup,
  IonThumbnail,
  IonToggle,
  IonReorderGroup,
  IonReorder,
  IonInput,
  IonTextarea,
  IonAccordion,
  IonAccordionGroup,
  IonRadio,
  IonRadioGroup,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonChip,
  IonNav,
  IonBadge,
  IonSelect,
  IonSelectOption,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonDatetime,
  IonDatetimeButton,
} from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/uncoded.css";

const app = createApp(App).use(IonicVue).use(router).use(pinia);

app.component("IonPage", IonPage);
app.component("IonContent", IonContent);
app.component("IonIcon", IonIcon);
app.component("IonItem", IonItem);
app.component("IonLabel", IonLabel);
app.component("IonSegment", IonSegment);
app.component("IonSegmentButton", IonSegmentButton);
app.component("IonCard", IonCard);
app.component("IonCardContent", IonCardContent);
app.component("IonCardHeader", IonCardHeader);
app.component("IonCardTitle", IonCardTitle);
app.component("IonCardSubtitle", IonCardSubtitle);
app.component("IonList", IonList);
app.component("IonNote", IonNote);
app.component("IonHeader", IonHeader);
app.component("IonButton", IonButton);
app.component("IonMenuButton", IonMenuButton);
app.component("IonButtons", IonButtons);
app.component("IonFooter", IonFooter);
app.component("IonToolbar", IonToolbar);
app.component("IonTitle", IonTitle);
app.component("IonMenu", IonMenu);
app.component("IonListHeader", IonListHeader);
app.component("IonItemGroup", IonItemGroup);
app.component("IonThumbnail", IonThumbnail);
app.component("IonToggle", IonToggle);
app.component("IonReorderGroup", IonReorderGroup);
app.component("IonReorder", IonReorder);
app.component("IonInput", IonInput);
app.component("IonTextarea", IonTextarea);
app.component("IonGrid", IonGrid);
app.component("IonCol", IonCol);
app.component("IonRow", IonRow);
app.component("IonAccordionGroup", IonAccordionGroup);
app.component("IonAccordion", IonAccordion);
app.component("IonRadio", IonRadio);
app.component("IonRadioGroup", IonRadioGroup);
app.component("IonBackdrop", IonBackdrop);
app.component("IonModal", IonModal);
app.component("IonCheckbox", IonCheckbox);
app.component("IonSpinner", IonSpinner);
app.component("IonChip", IonChip);
app.component("IonNav", IonNav);
app.component("IonBadge", IonBadge);
app.component("IonSelect", IonSelect);
app.component("IonSelectOption", IonSelectOption);
app.component("IonItemSliding", IonItemSliding);
app.component("IonItemOptions", IonItemOptions);
app.component("IonItemOption", IonItemOption);
app.component("IonDatetimeButton", IonDatetimeButton);
app.component("IonDatetime", IonDatetime);
app.component("IonRefresher", IonRefresher);
app.component("IonRefresherContent", IonRefresherContent);

router.isReady().then(() => {
  app.mount("#app");
});

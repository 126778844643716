import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useAuthStore = defineStore("authStore", () => {
  const _user = ref();

  const setUser = (user) => (_user.value = user);

  const user = computed(() => {
    return _user.value;
  });

  return { user, setUser };
});

<template>
  <ion-page>
    <ion-content>
      <div class="instructions fullwidth f2">uncoded mail tools</div>
      <div class="instructions fullwidth" @click="login" :style="{ opacity: user ? 0.3 : 1 }">1. first click here to authenticate</div>
      <div class="flex centered ft o5 cs" style="margin: -20px 0px 0px">(please check that popups are not disabled for this site)</div>
      <div class="flex centered">
        <div v-if="user" id="card">
          <!-- The signature container -->
          <table cellpadding="0" cellspacing="0" border="0" class="sh-src" style="margin: 0px; border-collapse: collapse">
            <tbody>
              <tr>
                <td align="left" style="padding: 0px 0px 6px 0px; vertical-align: top">
                  <p style="margin: 0px">
                    <img
                      src="https://mail.uncoded.com/images/arrows@2x.png"
                      alt=""
                      height="8"
                      width="12"
                      style="display: block; border: 0px; max-width: 12px"
                    />
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style="padding: 0px 0px 2px 0px; font-family: Arial, sans-serif; font-size: 16px; line-height: 17px; white-space: nowrap"
                >
                  <p
                    style="
                      font-family: Arial, sans-serif;
                      font-size: 16px;
                      line-height: 17px;
                      color: rgb(0, 0, 0);
                      white-space: nowrap;
                      margin: 0px;
                    "
                  >
                    {{ user?.givenName?.toLowerCase() }} {{ user.surname?.toLowerCase() }}
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style="padding: 0px 0px 2px 0px; font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap"
                >
                  <p
                    style="
                      font-family: Arial, sans-serif;
                      font-size: 12px;
                      line-height: 13px;
                      color: rgb(0, 0, 0);
                      white-space: nowrap;
                      margin: 0px;
                    "
                  >
                    {{ user?.jobTitle?.toLowerCase() }}
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  style="padding: 0px 0px 8px 0px; font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap"
                >
                  <p
                    style="
                      font-family: Arial, sans-serif;
                      font-size: 12px;
                      line-height: 13px;
                      color: rgb(0, 0, 0);
                      white-space: nowrap;
                      margin: 0px;
                    "
                  >
                    <a :href="`tel:${user?.mobilePhone}`" style="color: rgb(0, 0, 0); text-decoration: none !important"
                      ><span style="color: rgb(0, 0, 0); text-decoration: none !important">{{ user?.mobilePhone }}</span></a
                    >
                  </p>
                </td>
              </tr>

              <tr>
                <td style="padding: 0px 0px 6px 0px">
                  <a href="https://uncoded.com" target="_blank"
                    ><img
                      alt="Uncoded Logo"
                      src="https://mail.uncoded.com/images/logo@2x.gif"
                      height="44"
                      width="247"
                      style="max-width: 247px; margin-left: -3px"
                  /></a>
                </td>
              </tr>

              <tr>
                <td
                  style="padding: 0px 0px 8px 0px; font-family: Arial, sans-serif; font-size: 13px; line-height: 17px; white-space: nowrap"
                >
                  <p
                    style="
                      font-family: Arial, sans-serif;
                      font-size: 12px;
                      line-height: 13px;
                      color: rgb(0, 0, 0);
                      white-space: nowrap;
                      margin: 0px;
                    "
                  >
                    <a href="https://uncoded.com" style="color: rgb(0, 0, 0); text-decoration: none !important"
                      ><span style="color: rgb(0, 0, 0); text-decoration: none !important">uncoded.com</span></a
                    >
                  </p>
                </td>
              </tr>

              <tr>
                <td style="padding: 0px">
                  <table cellpadding="0" cellspacing="0" border="0" style="margin: 0px; border-collapse: collapse">
                    <tr>
                      <td width="15" style="font-size: 0px; line-height: 0px; padding: 0px 0px 0px 0px">
                        <p style="margin: 0px">
                          <a href="https://www.instagram.com/uncodedcom" target="_blank"
                            ><img
                              src="https://mail.uncoded.com/images/insta@2x.png"
                              alt="Instagram"
                              width="15"
                              height="15"
                              style="display: block; border: 0px; max-width: 15px"
                          /></a>
                        </p>
                      </td>

                      <td width="11" style="padding: 0px 0px 1px"></td>

                      <td width="15" style="font-size: 0px; line-height: 0px; padding: 0px 0px 0px 0px">
                        <p style="margin: 0px">
                          <a href="https://www.linkedin.com/company/uncodedcom" target="_blank"
                            ><img
                              src="https://mail.uncoded.com/images/linkedin@2x.png"
                              alt="LinkedIn"
                              width="15"
                              height="15"
                              style="display: block; border: 0px; max-width: 15px"
                          /></a>
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex centered" v-if="user">
        <div v-if="editing" style="min-width: 400px">
          <ion-item>
            <ion-label style="--color: darkorange" position="stacked"> name</ion-label>
            <ion-input v-model="user.givenName"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label style="--color: darkorange" position="stacked">Last name</ion-label>
            <ion-input v-model="user.surname"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label style="--color: darkorange" position="stacked">Job title</ion-label>
            <ion-input v-model="user.jobTitle"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label style="--color: darkorange" position="stacked">Mobile phone number</ion-label>
            <ion-input v-model="user.mobilePhone"></ion-input>
          </ion-item>
        </div>
        <div v-else>
          <ion-button class="ft" @click="editing = true">Customise signature</ion-button>
        </div>
      </div>
      <div class="instructions fullwidth" v-if="user" :style="{ opacity: copied ? 0.3 : 1 }" @click="copy">
        2. now click here to copy to clipboard
      </div>
      <div class="instructions fullwidth" v-if="copied">3. finally, go to outlook and paste your signature</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { login, logout } from "@/firebase";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { toastController } from "@ionic/vue";
import { ref } from "vue-demi";

export default {
  setup() {
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const copied = ref(false);
    const editing = ref(false);

    const copy = async () => {
      editing.value = false;
      const toast = await toastController.create({
        message: "Signature copied to clipboard",
        duration: 2000,
        cssClass: "toast",
        position: "bottom",
        // icon: icon,
      });
      const card = document.getElementById("card");
      copyFormatted(card.innerHTML);
      copied.value = true;
      toast.present();
    };

    function copyFormatted(html) {
      var container = document.createElement("div");
      container.innerHTML = html;
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = 0;
      // Mount the container to the DOM to make `contentWindow` available
      // [3]
      document.body.appendChild(container);
      console.log(container.innerHTML);
      // Copy to clipboard
      // [4]
      window.getSelection().removeAllRanges();

      var range = document.createRange();
      range.selectNode(container);
      window.getSelection().addRange(range);

      // [5.3]
      document.execCommand("copy");

      // Remove the container
      // [6]
      document.body.removeChild(container);
    }

    return { login, logout, user, copy, copied, editing };
  },
};
</script>

<style>
/* Use the @font-face rule to specify the custom font */
@font-face {
  font-family: "Akkurat Pro";
  src: url("https://mail.uncoded.com/fonts/AkkuratPro-Regular.woff2") format("woff2"),
    url("https://mail.uncoded.com/fonts/AkkuratPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Akkurat-Mono";
  src: url("https://mail.uncoded.com/fonts/Akkurat-Mono.woff2") format("woff2"),
    url("https://mail.uncoded.com/fonts/Akkurat-Mono.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#card {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid darkgray;
  padding: 20px;
  min-width: 400px;
  min-height: 300px;
  transform: rotateZ(-2deg);
  box-shadow: gray 5px 5px 10px;
  margin: 20px;
  background-color: white;
}

.instructions {
  color: darkorange;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  padding: 0px 20px;
  font-family: "Akkurat Pro";
  font-size: 1.5em;
}
</style>
